import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import styled from "@emotion/styled";
import "./contact.css";
import TextArea from "../TextArea/TextArea";
import SendIcon from "@mui/icons-material/Send";
import Notification from "../Notification/Notification";
import { ModeContext } from "../../App";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const CssTextField = styled(TextField)({
    "& MuiFormLabel-root-MuiInputLabel-root": {
        color: "green",
    },

    "& .MuiOutlinedInput-root": {
        "&fieldset": {
            borderColor: "grey",
        },

        fieldset: {
            borderColor: "grey",
        },

        "&:hover fieldset": {
            borderColor: "green",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#6F7E8C",
        },
    },
});

function Contact() {
    const darkmode = useContext(ModeContext);
    const [successMessage, setsuccessMessage] = useState(false);
    const [errorMessage, seterrorMessage] = useState(false);
    const [messageNotify, setMessageNotify] = useState("");
    const [loading, setLoading] = useState(false);

    const sendMessage = (event) => {
        setLoading(true);
        event.preventDefault();
        if (validNameForm && isValidEmailForm) {
            const data = {
                from_name: name,
                message: inputValue,
                email_id: email,
            };

            axios
                .post(`${process.env.REACT_APP_SERVER}/submit`, data)
                .then((res) => {
                    setLoading(false);
                    seterrorMessage(false);
                    setsuccessMessage(true);
                    setMessageNotify("email sent successfully");
                })
                .catch((error) => {
                    setLoading(false);
                    seterrorMessage(true);
                    setsuccessMessage(false);
                    setMessageNotify("something went wrong");
                });
        } else {
            setLoading(false);
            seterrorMessage(true);
            setsuccessMessage(false);
            setMessageNotify("Please fill all fields correctly");
        }
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        seterrorMessage(false);
        setsuccessMessage(false);
    };
    const [name, setName] = useState("");
    const [validName, setValidName] = useState(true);
    const [validNameForm, setValidNameForm] = useState(false);
    const [isValidEmailForm, setIsValidEmailForm] = useState(false);
    const [validMessageForm, setValidMessageForm] = useState(false);

    const handleInputName = (value) => {
        const showValue = value.trimStart();
        setName(showValue);
        setValidName(validateName(showValue));
        setValidNameForm(validateName(showValue));
    };

    const validateName = (name) => {
        const nameWithoutSpaces = name.trim();
        const nameRegex = /^[a-zA-Z\s]+$/;
        return nameRegex.test(nameWithoutSpaces);
    };

    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setIsValidEmail(validateEmail(newEmail));
        setIsValidEmailForm(validateEmail(newEmail));
    };

    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    const [message, setMessage] = useState("");
    const [validMessage, setValidMessage] = useState(true);

    const handleInputMessage = (value) => {
        setValidMessage(validateMessage(value));
        setValidMessageForm(validateMessage(value));
    };

    const validateMessage = (value) => {
        const messageWithoutSpaces = value.trim();
        if (
            messageWithoutSpaces.length >= 10 &&
            messageWithoutSpaces.length <= 1000
        ) {
            setMessage(messageWithoutSpaces);
            return true;
        }
        return false;
    };

    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (newValue) => {
        setInputValue(newValue.trimStart());
    };

    return (
        <>
            <Grid
                container
                spacing={2}
                paddingTop={2}
                paddingLeft={2}
                id="contact"
                sx={{}}
            >
                <Grid item xs={12} sm={6} md={8} p={3}>
                    <iframe
                        title={"location"}
                        src={
                            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62241.30634469805!2d77.63299754224848!3d12.838000429000024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6c8a7750e1c3%3A0x4a5cfc0fce5af71d!2sElectronic%20City%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1736936058424!5m2!1sen!2sin"
                        }
                        Width={"100%"}
                        height={"450"}
                        sx={{ border: "0px" }}
                        loading={"lazy"}
                        referrerPolicy={"no-referrer-when-downgrade"}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} p={1}>
                    <Box
                        flexDirection={"column"}
                        padding={{ sm: 0, md: 1 }}
                        display={"flex"}
                    >
                        <Typography
                            variant="h5"
                            textAlign={"center"}
                            sx={{
                                fontFamily: "monospace",
                                mr: 2,
                                fontWeight: 500,
                                letterSpacing: ".1rem",
                                color: darkmode ? "white" : "black",
                                fontSize: { xs: "1.1rem", md: "2rem" },
                                animation: "ease-in",
                                textTransform: "none",
                            }}
                        >
                            Contact Me
                        </Typography>
                        <Typography
                            variant="body5"
                            paddingTop={3}
                            sx={{
                                color: "grey",
                                fontFamily: "sans-serif",
                                fontStyle: "initial",
                                fontSize: { xs: "14px", md: "16px" },
                            }}
                        >
                            Feel welcome to contact me for inquiries. I'm open
                            to connecting and exploring potential projects
                            together. Let's Collaborate &#128522;
                        </Typography>
                        <FormGroup sx={{ marginTop: "1rem" }}>
                            <CssTextField
                                required
                                label="Name"
                                variant="outlined"
                                sx={{
                                    paddingTop: { xs: "6px", md: "8px" },
                                    fontSize: { xs: "8px", md: "14px" },
                                    marginBottom: { xs: "1rem", md: "1.2rem" },
                                    size: { xs: "small", md: "Normal" },
                                    "& .MuiOutlinedInput-input": {
                                        fontSize: { xs: "14px", md: "14px" },
                                    },
                                    "& label.Mui-focused": {
                                        color: darkmode
                                            ? "#6F7E8C"
                                            : "black !important",
                                    },
                                    "& input": {
                                        color: darkmode
                                            ? "white"
                                            : "black !important",
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottomColor: "green",
                                        color: darkmode
                                            ? "white"
                                            : "black !important",
                                    },
                                }}
                                error={!validName}
                                helperText={
                                    !validName ? "Please enter a name" : ""
                                }
                                value={name}
                                onChange={(e) => {
                                    handleInputName(e.target.value);
                                }}
                            />

                            <CssTextField
                                required
                                sx={{
                                    marginBottom: { xs: "1rem", md: "1.2rem" },
                                    paddingTop: { xs: "6px", md: "8px" },
                                    fontSize: { xs: "8px", md: "14px" },
                                    "& .MuiOutlinedInput-input": {
                                        fontSize: { xs: "14px", md: "14px" },
                                    },
                                    "& label.Mui-focused": {
                                        color: darkmode
                                            ? "#6F7E8C"
                                            : "black !important",
                                    },
                                    "& input": {
                                        color: darkmode
                                            ? "white"
                                            : "black !important",
                                    },
                                }}
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={handleEmailChange}
                                error={!isValidEmail}
                                helperText={
                                    !isValidEmail
                                        ? "Please enter a valid email"
                                        : ""
                                }
                            />

                            <TextArea
                                required
                                sx={{
                                    "& .MuiOutlinedInput-input": {
                                        fontSize: { xs: "8px", md: "14px" },
                                    },
                                }}
                                InputProps={{
                                    size: { xs: "small", md: "medium" },
                                }}
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                            <Button
                                type="submit"
                                sx={{
                                    width: { sm: "100%", md: "100%" },
                                    fontSize: {
                                        xs: "13px",
                                        sm: "10px",
                                        md: "16px",
                                    },
                                    marginTop: { xs: "1rem", md: "1.2rem" },
                                    textTransform: "none",
                                }}
                                onClick={sendMessage}
                                variant={"contained"}
                                endIcon={
                                    loading ? (
                                        <CircularProgress
                                            size={20}
                                            sx={{ color: "white" }}
                                        />
                                    ) : (
                                        <SendIcon />
                                    )
                                }
                            >
                                Send
                            </Button>
                            {successMessage && (
                                <Notification
                                    handleClose={handleClose}
                                    successMessage={successMessage}
                                    messageNotify={messageNotify}
                                />
                            )}

                            {errorMessage && (
                                <Notification
                                    handleClose={handleClose}
                                    errorMessage={errorMessage}
                                    messageNotify={messageNotify}
                                />
                            )}
                        </FormGroup>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default Contact;
