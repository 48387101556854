import { Box, Card, CardMedia, Grid } from "@mui/material";
import React, { useContext } from "react";
import { Typography } from "@mui/material";
import "./project.css";
import urlshorterner from "../../assets/Url-shortener.png";
import keralaTourism from "../../assets/kerala-tourism.png";
import Chat from "../../assets/chat.png";
import Farmfresh from "../../assets/farm-fresh.png";
import Budget from "../../assets/budget.png";
import { ModeContext } from "../../App";

const Project = () => {
    const darkmode = useContext(ModeContext);

    const cardStyle = {
        width: "100%",
        height: "450px",
        display: "flex",
        marginBottom: "20px",
    };

    // useEffect(() => {
    //     let flipTimeout;
    //     let flipBackTimeout;

    //     const flipCard = () => {
    //         setIsFlipped(true);
    //         flipTimeout = setTimeout(() => {
    //             setIsFlipped(false);
    //         }, 1000);
    //     };

    //     const flipBack = () => {
    //         flipBackTimeout = setTimeout(() => {
    //             setIsFlipped(false);
    //         }, 2000);
    //     };

    //     flipCard();

    //     const intervalId = setInterval(() => {
    //         flipCard();
    //         flipBack();
    //     }, 3000);

    //     return () => {
    //         clearInterval(intervalId);
    //         clearTimeout(flipTimeout);
    //         clearTimeout(flipBackTimeout);
    //     };
    // }, []);

    return (
        <>
            <Box padding={3} id="projects">
                <Typography
                    variant="h5"
                    textAlign={"center"}
                    sx={{
                        fontFamily: "monospace",
                        mr: 2,
                        fontWeight: 500,
                        color: "grey",
                        fontSize: { xs: "1.1rem", md: "2rem" },
                        animation: "ease-in",
                    }}
                >
                    &#60;/&#62;
                </Typography>

                <Typography
                    variant="h5"
                    textAlign={"center"}
                    sx={{
                        fontFamily: "monospace",
                        mr: 2,
                        fontWeight: 500,

                        color: darkmode ? "white" : "black",
                        fontSize: { xs: "1.1rem", md: "2rem" },
                        animation: "ease-in",
                    }}
                >
                    Apps I've Built
                </Typography>
                <Grid
                    container
                    spacing={2}
                    p={1}
                    mt={1}
                    sx={{
                        flexWrap: "wrap",
                        justifyContent: {
                            xs: "flex-start",
                            md: "flex-start",
                        },
                    }}
                >
                    <Grid item xs={12} sm={6} md={4}>
                        <a
                            href="https://url-xhu6.onrender.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Card variant="outlined" style={cardStyle}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={urlshorterner}
                                    alt="urlshorterner"
                                    sx={{ objectFit: "fill" }}
                                />
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <a
                            href="https://kerala-tourism-d5uk.onrender.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Card variant="outlined" style={cardStyle}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={keralaTourism}
                                    alt="keralaTourism"
                                    sx={{ objectFit: "fill" }}
                                />
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <a
                            href="https://www.loom.com/share/4a6e039e2c8f4a01817432224eb1fde4"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Card variant="outlined" style={cardStyle}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={Chat}
                                    alt="ChatApp"
                                    sx={{ objectFit: "fill" }}
                                />
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <a
                            href="https://farm-fresh-modern.netlify.app"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Card variant="outlined" style={cardStyle}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={Farmfresh}
                                    alt="farmfresh"
                                    sx={{ objectFit: "fill" }}
                                />
                            </Card>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <a
                            href="https://url-xhu6.onrender.com/_IXRlCX8d"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Card variant="outlined" style={cardStyle}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={Budget}
                                    alt="budget"
                                    sx={{ objectFit: "fill" }}
                                />
                            </Card>
                        </a>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default Project;
