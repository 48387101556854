import { Box, Button, ButtonGroup, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import MemoryRoundedIcon from "@mui/icons-material/MemoryRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "@emotion/styled";
import { ModeContext } from "../../App";

const GreenCheckCircle = styled(CheckCircleIcon)`
    color: green;
`;

const CustomButton = styled(Button)(({ theme, value }) => ({
    color: value,
    border: "1px solid lightgreen",
    textTransform: "none",
    fontSize: "11px",
    width: "120px",
}));

const Skills = () => {
    const darkmode = useContext(ModeContext);
    const Primaryskills = [
        {
            skill: "JavaScript",
            url: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
        },
        {
            skill: "Node JS",
            url: "https://nodejs.org/en",
        },
        {
            skill: "React JS",
            url: "https://react.dev/",
        },
        {
            skill: "MongoDB",
            url: "https://www.mongodb.com/",
        },
        {
            skill: "Angular",
            url: "https://angular.io/",
        },
        {
            skill: "Bootstrap",
            url: "https://getbootstrap.com/",
        },
        {
            skill: "Electron",
            url: "https://www.electronjs.org/",
        },
        {
            skill: "Docker",
            url: "https://www.docker.com/",
        },
        {
            skill: "Material UI",
            url: "https://mui.com/material-ui/",
        },
        {
            skill: "GCP",
            url: "https://cloud.google.com/docs",
        },
    ];

    return (
        <Box padding={3} id="skills">
            <Typography
                variant="h1"
                textAlign={"center"}
                sx={{
                    fontFamily: "monospace",
                    mr: 2,
                    fontWeight: 500,
                    color: "grey",
                    fontSize: { xs: "2rem", md: "2rem" },
                    animation: "ease-in",
                }}
            >
                <MemoryRoundedIcon fontSize={"20px"} />
            </Typography>

            <Typography
                variant="h5"
                textAlign={"center"}
                sx={{
                    fontFamily: "monospace",
                    mr: 2,
                    fontWeight: 500,
                    color: darkmode ? "white" : "black",
                    animation: "ease-in",
                    fontSize: { xs: "1.1rem", md: "2rem" },
                }}
            >
                Skills & Technologies
            </Typography>
            <Box padding={3} textAlign={"center"}>
                <Grid container spacing={2}>
                    {Primaryskills.map((item, index) => (
                        <Grid key={index} item xs={6} sm={6} md={4}>
                            <a href={item.url} target="_blank" rel="noreferrer">
                                <ButtonGroup
                                    disableElevation
                                    aria-label="Disabled elevation buttons"
                                >
                                    <CustomButton
                                        startIcon={<GreenCheckCircle />}
                                        value={darkmode ? "white" : "black"}
                                    >
                                        {item.skill}
                                    </CustomButton>
                                </ButtonGroup>
                            </a>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Skills;
